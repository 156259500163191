<template>
  <v-container>
    <base-material-card
      color="indigo"
      icon="mdi-home-search"
      inline
      class="px-5 py-3"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          {{ categoryTitle }}
        </div>
      </template>
      <v-row
      >
        <v-col cols="12" md="3">
          <v-text-field
            v-model="filters.min_price"
            label="Мінімальна ціна"
            clearable
            @input="getTableData"
            @click:clear="getTableData"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="filters.max_price"
            label="Максимальна ціна"
            clearable
            @input="getTableData"
            @click:clear="getTableData"
          />
        </v-col>
        <v-spacer/>
        <v-col cols="auto">
          <v-btn
            color="primary"
            @click="toggleNewItemModal()"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            Додати
          </v-btn>
        </v-col>
      </v-row>

      <v-divider class="mt-3 mb-3"/>

      <v-list-item-group
        v-for="group in tableData"
        :key="group.price_range"
        class="mb-5"
        light
      >
        <v-list-item-title class="font-weight-bold text-h5">
          {{ group.price_range }}
        </v-list-item-title>

        <v-list-item
          v-for="(child, i) in group.apartments"
          :key="`child_${child.title + i}`"
        >
          <v-checkbox class="pr-2" v-model="child.is_finished" @click="updateFinishApartment(child)"></v-checkbox>
          <v-list-item-title :class="{crossed: child.is_crossed}" v-html="child.title" style="white-space: normal">
          </v-list-item-title>
          <v-icon
            right
            @click="updateCrossApartment(child)"
          >
            mdi-check-underline
          </v-icon>

          <v-icon
            right
            @click="selectItem(child.id)"
          >
            mdi-eye
          </v-icon>
        </v-list-item>
      </v-list-item-group>
    </base-material-card>

    <v-card class="v-card--material px-5 py-3 mt-10" color="lightgray" style="opacity: 0.7">
      <v-card-title>Завершені</v-card-title>

      <v-list-item-group
        :key="renderListKey"
        light
      >
        <v-list-item
          v-for="(child, i) in finishedApartments"
          :key="`child_${child.title + i}`"
        >
          <v-checkbox class="pr-2" v-model="child.is_finished" @click="updateFinishApartment(child)"></v-checkbox>
          <v-list-item-title :class="{crossed: child.is_crossed}" v-html="child.title" style="white-space: normal">
          </v-list-item-title>
          <v-icon
            right
            @click="selectItem(child.id)"
          >
            mdi-eye
          </v-icon>
        </v-list-item>
      </v-list-item-group>
    </v-card>

    <ItemModal
      :modal-is-show="modalIsShow"
      :item="currentItem"
      @closeModal="toggleModal"
      @showFullImage="toggleShowImage"
      v-if="currentItem"
    />

    <NewItemModal
      :modal-is-show="newItemModalIsShow"
      @closeModal="toggleNewItemModal"
    />

    <ImageModal
      v-if="currentItem"
      :item="currentItem"
      :modal-is-show="showFullImage"
      :image-index="imageIndex"
    />
  </v-container>
</template>
<script>
import draggable from 'vuedraggable';
import ItemModal from './ItemModal.vue';
import NewItemModal from "./NewItemModal.vue";
import ImageModal from "../components/apartment/ImageModal.vue";
import category from "../../../services/category";
import apartment from "../../../services/apartment";

export default {
  name: 'CategoryPage',
  components: {
    ItemModal,
    NewItemModal,
    draggable,
    ImageModal
  },
  data() {
    return {
      showFullImage: false,
      imageIndex: 0,
      categoryTitle: '',
      modalIsShow: false,
      newItemModalIsShow: false,
      editMode: false,
      tableData: [],
      finishedApartments: [],
      loading: true,
      renderListKey: 0,
      activeSubCategory: 0,
      search: '',
      currentItem: null,
      filters: {
        min_price: '',
        max_price: ''
      }
    }
  },
  watch: {
    tableData: {
      handler() {
        this.renderListKey = this.renderListKey + 1;
      },
      deep: true
    }
  },
  mounted() {
    this.getTableData();
  },

  methods: {
    getTableData() {
      category.show(this.$route.params.id, this.filters)
        .then((res) => {
          if (res) this.categoryTitle = res.data.data.title;
          this.tableData = res.data.data.grouped_apartments;
          this.finishedApartments = res.data.data.finished_apartments
        })
    },

    toggleModal() {
      this.modalIsShow = !this.modalIsShow;
      this.showFullImage = false
    },

    toggleShowImage(index) {
      this.imageIndex = index
      this.showFullImage = true
    },

    selectItem(itemId) {
      apartment.show(itemId)
        .then((res) => {
          if (res) this.currentItem = res.data.data
        })
      this.toggleModal()
    },

    toggleNewItemModal() {
      this.newItemModalIsShow = !this.newItemModalIsShow;
    },

    updateCrossApartment(item) {
      item.is_crossed = !item.is_crossed
      apartment.updateApartmentCross(item)
    },

    updateFinishApartment(item) {
      apartment.updateApartmentFinish(item)
        .then(() => {
          this.$router.go(0)
        })
    }
  }
};
</script>

<style lang="css">
.finished-list {
  background-color: lightgray;
  opacity: 0.8;
}

 .crossed {
   text-decoration: line-through;
   opacity: 0.7;
 }
</style>
