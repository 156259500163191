<template>
  <v-dialog
    :retain-focus="false"
    v-model="modalIsShow"
    width="700"
    @click:outside="closeModal"
  >
    <v-card>
      <v-card-title
        class="white--text primary"
        align="left"
      >
        Додати
      </v-card-title>
      <v-container>
        <v-text-field
          label="Ціна"
          prefix="$"
          v-model="item.price"
          :error-messages="storeErrors.price"
        ></v-text-field>

        <v-text-field
          label="Адреса"
          v-model="item.address"
          :error-messages="storeErrors.address"
        ></v-text-field>

        <v-text-field
          label="Кількість кімнат"
          v-model="item.rooms"
          :error-messages="storeErrors.rooms"
        ></v-text-field>

        <span class="d-flex">
          <v-text-field
            class="pr-4"
            label="Поверх"
            v-model="item.floor"
            :error-messages="storeErrors.floor"
          ></v-text-field>

          <v-text-field
            label="Усього поверхів"
            v-model="item.total_floors"
            :error-messages="storeErrors.total_floors"
          ></v-text-field>
        </span>

        <v-text-field
          v-model="item.square_meters"
          :error-messages="storeErrors.square_meters"
        >
          <template #label>
            <label>Площа приміщення (м<sup>2</sup>)</label>
          </template>
        </v-text-field>

        <v-text-field
          v-model="item.area"
          :error-messages="storeErrors.area"
        >
          <template #label>
            <label>Площа земельної ділянки (га)</label>
          </template>
        </v-text-field>

        <v-file-input
          v-model="item.images"
          label="Фото"
          counter
          multiple
          small-chips
        ></v-file-input>

        <v-textarea
          rows="2"
          auto-grow
          v-model="item.note"
          :error-messages="storeErrors.note"
          label="Нотатки"
        />

      </v-container>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="default"
          @click="closeModal()"
        >
          Закрити
        </v-btn>
        <v-btn
          color="blue"
          @click="storeApartment()"
        >
          Зберегти
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import apartment from "../../../services/apartment";

export default {
  name: 'ItemModal',
  props: {
    modalIsShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      item: {
        images: [],
        category_id: this.$route.params.id,
        price: '',
        address: '',
        rooms: '',
        floor: '',
        total_floors: '',
        square_meters: '',
        area: '',
        note: ''
      },

      storeErrors: {
        images: [],
        price: [],
        address: [],
        rooms: [],
        floor: [],
        total_floors: [],
        square_meters: [],
        area: [],
        note: []
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },

    storeApartment() {
      apartment.store(this.item)
        .then((res) => {
          if (res) this.$router.go(0);
        })
        .catch((err) => {
          if (err.status === 422) this.storeErrors = err.data.errors;
        })
    }
  }
};
</script>
